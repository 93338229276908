import Input from './Input'
//import Rails from 'rails-ujs'

class InputSearch extends Input {
  constructor(componentName){
    super(componentName)

    this.lastSearch = ''
  }


  init(component) {
    const element = component.element

    // Method inherited from Input component
    this.addCustomEvents(element)
    this.checkForFocus(element)
    this.checkForValues(element)

    // Search method
    this.watchInputValue(component)
  }


  watchInputValue(component) {
    const searchInput = component.element
    const clearSearch = searchInput.getElementsByClassName('btn--clear-search')[0]
    let timeOut

    searchInput.addEventListener('keyup', (e) => {
      const newSearch = searchInput.value
      // if (this.lastSearch !== newSearch && newSearch.length >= 2 || newSearch.length === 0) {

      //   clearTimeout(timeOut)
      //   timeOut = setTimeout(() => {
      //     this.lastSearch = newSearch
      //     this.initSearch(searchInput, newSearch)
      //   }, 500)
      // }
      if (e.keyCode == 13) this.initSearch(searchInput, newSearch)

      // Hide clearSearch button when input is empty
      if(clearSearch) {
        if(newSearch.length === 0) {
          clearSearch.classList.add('is-hidden')
        } else {
          clearSearch.classList.remove('is-hidden')
        }
      }
    }, false)

    // btn--clear-search
    if(clearSearch) {
      clearSearch.addEventListener('click', () => {
        searchInput.value = ''

        const newSearch = searchInput.value
        if (this.lastSearch !== newSearch && newSearch.length >= 2 || newSearch.length === 0) {
          clearTimeout(timeOut)
          timeOut = setTimeout(() => {
            this.lastSearch = newSearch
            this.initSearch(searchInput, newSearch)
          }, 500)
        }

        if(clearSearch) {
          if(newSearch.length === 0) {
            clearSearch.classList.add('is-hidden')
          } else {
            clearSearch.classList.remove('is-hidden')
          }
        }
      })
    }
  }



  initSearch(searchInput, value) {
    const params  = value.length > 0 ? `?q=${value}` : ''
    const formURL = searchInput.form.getAttribute('action')
    const url     = `${formURL}${params}`


    // Actions
    //________
    this.searchInProgress(true)
    searchInput.form.submit()
    // Rails.fire(searchInput.form, 'submit')
    // this.updateUrl(url)
  }


  searchInProgress(active = false) {
    const resultsWrapper = document.getElementById('search')
    if(resultsWrapper) {
      active ? resultsWrapper.classList.add('searching') : resultsWrapper.classList.remove('searching')
    }
  }


  updateUrl(url) {
    const title = document.getElementsByTagName('title')[0].textContent
    var state = { title: title }
    history.pushState(state, title, url)
  }


  updateResult(string, searchValue, loadPricesURL, ids) {
    if(searchValue === this.lastSearch) {
      const doc     = new DOMParser().parseFromString(string, 'text/html')
      const results = doc.body.firstChild

      // results.classList.add('search')
      app.components.product.loadPrices(loadPricesURL, ids)

      const resultsWrapper = document.getElementById('search')
      if(!!resultsWrapper) {
        resultsWrapper.innerHTML = ''
        resultsWrapper.appendChild(results)
        // debugger
        setTimeout(() => {
          this.searchInProgress(false)
        }, 200)
      }
    }
  }


  blur(element) {
    element.blur()
  }


  postAnalyticsData(lang, term) {
    dataLayer.push({
      'event': 'Search',
      'lang': lang,
      'term': term
    })
  }
}
export default InputSearch
