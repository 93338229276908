import BaseComponent from './BaseComponent'

class InputFile extends BaseComponent {
  constructor(componentName){
    super(componentName)
  }

  init(component) {
    this.addCustomEvents(component)
  }

  addCustomEvents(component) {
    component.element.addEventListener('change', () => {
      this.updatePreview(component)
    })
  }

  enableDragAndDrop(form) {
    let droppedFiles = false

    function prevent(e) {
      e.preventDefault()
			// e.stopPropagation()
    }

    function dragging() {
      form.classList.add('dragging')
    }
    function dropped() {  form.classList.remove('dragging') }

    addMultipleListener(form, 'drag dragstart dragend dragover dragenter dragleave drop', prevent)
    addMultipleListener(form, 'dragover dragenter', dragging)
    addMultipleListener(form, 'dragleave dragend drop', dropped)

    form.addEventListener('drop', (e) => {
      const input = e.target.parentElement.parentElement.getElementsByTagName('input')[0]

      if(input) {
        const component = this.getComponentById(input.id)
        // 0) Store dropped image as the input value
        input.files = e.dataTransfer.files
        // 1) Update preview
        this.updatePreview(component)
        // 2) Submit if autosubmit
        if(component.element.dataset.autosubmit == 'true') app.components.form.submitDataOfForm(form)
      } else {
        console.warn(`🦑 No input file detected, check in InputFile.js the 'const input' declaration`)
      }
    })

    function highlightDropzoneOverOn(e) {
      const dropzone = e.target
      dropzone.classList.add('dragging')
    }

    function highlightDropzoneOverOff(e) {
      const dropzone = e.target
      dropzone.classList.remove('dragging')
    }

    const dropzones = form.getElementsByClassName('dropzone')
    for(let dropzone of dropzones) {
      addMultipleListener(dropzone, 'dragover', highlightDropzoneOverOn)
      addMultipleListener(dropzone, 'dragleave', highlightDropzoneOverOff)
    }
  }

  autoSubmit(component) {
    const inputFile  = component.element
    if(inputFile.getAttribute('autosubmit') == 'true') {
      // inputFile.form.submit()
      app.components.form.submitDataOfForm(inputFile.form)
    }
  }


  updatePreview(component, droppedFiles = false) {
    const inputFile      = component.element
    const fileNames      = inputFile.parentElement.getElementsByClassName('name')[0]
    const form           = inputFile.form
    const files          = inputFile.files

    const previewWrapper = inputFile.parentElement.getElementsByClassName('file-preview-wrapper')[0]

    if(previewWrapper) {
      this.removeOldPreviews(fileNames, previewWrapper)

      if(files.length > 0) {
        if(inputFile.multiple) {
          // for(let file of files) {
          //   this.displayPreviews({ previewWrapper, inputFile, fileNames, file })
          // }
          this.autoSubmit(component)
        } else {
          const file = files[0]
          this.displayPreviews({ previewWrapper, inputFile, fileNames, file })
        }
        fileNames.classList.add('has-content')
        previewWrapper.classList.add('has-content')
      }
    }
  }

  displayPreviews({ previewWrapper, inputFile, fileNames, file }) {
    if(this.validFileType(file, inputFile.accept)) {
      if(file.type == 'application/pdf' || file.type == 'application/zip') {
        const iconString = `
          <i class='preview-type'>
            <img src='/assets/cms/i--${file.type.split('application/')[1]}.svg' alt='Drop file here'/>
          </i>
        `
        const icon = app.parser.parseToHTML(iconString)
        previewWrapper.appendChild(icon)
      } else {
        const preview = document.createElement('figure')
        const image   = document.createElement('img')
        preview.classList.add('preview')
        image.src = window.URL.createObjectURL(file)

        preview.appendChild(image)
        previewWrapper.appendChild(preview)

        fileNames.textContent += ` ${file.name}`
      }
    } else {
      // TODO si no es válido añadir un preview diciendo que no se subirá
      // para navegadores que ignoran el attr accept=''
    }
  }

  validFileType(file, inputAccept) {
    const typesAccepted = inputAccept.split(', ')

    for(let type of typesAccepted) {
      if(file.type === type) return true
    }

    return false
  }


  updateSlider(slider) {
    app.components.sliderGallery.update(slider)
  }


  removeOldPreviews(fileNames, slidesList) {
    fileNames.textContent = ''
    fileNames.classList.remove('has-content')
    while(slidesList.firstChild) {
      slidesList.removeChild(slidesList.firstChild)
    }
  }

  clearInput() {
    // TODO: REMOVE data from input
    // 1) Clear input
    // 2) Clear previews --> removeOldPreviews
  }
}

export default InputFile
