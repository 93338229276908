import 'babel-polyfill' // Webpack needs
import Rails from '@rails/ujs' // Rails needs
Rails.start()

////////////////////// HELPERS //////////////////////////
/////////////////////////////////////////////////////////
import LazyLoad         from 'vanilla-lazyload'
import States           from '../cms/js/helpers/StatesMachine'
import Device           from '../cms/js/helpers/Device'
import Parser           from '../cms/js/helpers/Parser'
import Ajax             from '../cms/js/helpers/Ajax'
import '../cms/js/helpers/HandyFunctions'

////////////////////// COMPONENTS ////////////////////////
//////////////////////////////////////////////////////////
import AjaxTrigger      from '../cms/js/components/AjaxTrigger'
import Accordion        from '../cms/js/components/Accordion'
import Alert            from '../cms/js/components/Alert'
import Clipboard        from '../cms/js/components/Clipboard'
import Instance         from '../cms/js/components/Instance'
import DragAndDrop      from '../cms/js/components/DragAndDrop'
import Dropzone         from '../cms/js/components/Dropzone'
import DropdownTrigger  from '../cms/js/components/DropdownTrigger'
import Form             from '../cms/js/components/Form'
import Input            from '../cms/js/components/Input'
import InputCheckbox    from '../cms/js/components/InputCheckbox'
import InputColorPicker from '../cms/js/components/InputColorPicker'
import InputDatePicker  from '../cms/js/components/InputDatePicker'
import InputNumber      from '../cms/js/components/InputNumber'
import InputFile        from '../cms/js/components/InputFile'
import InputPassword    from '../cms/js/components/InputPassword'
import InputRadio       from '../cms/js/components/InputRadio'
import InputRange       from '../cms/js/components/InputRange'
import InputSearch      from '../cms/js/components/InputSearch'
import InputSelect      from '../cms/js/components/InputSelect'
import InputTextArea    from '../cms/js/components/InputTextArea'
import ModalTrigger     from '../cms/js/components/ModalTrigger'
import Modal            from '../cms/js/components/Modal'
import Navbar           from '../cms/js/components/Navbar'
import SliderGallery    from '../cms/js/components/SliderGallery'
import SliderTabs       from '../cms/js/components/SliderTabs'
import Tooltip          from '../cms/js/components/Tooltip'
import Vimeo            from '../cms/js/components/Vimeo'

class Project {
  constructor() {
    this.device     = new Device()
    this.states     = new States()
    this.parser     = new Parser()
    this.ajax       = new Ajax()
    this.lazyLoad   = new LazyLoad()
    this.components = {}
  }
}
//////////////////// CONTENT LOAD ////////////////////////
/////////////////////////////////////////////////////////
document.addEventListener('DOMContentLoaded', () => {
  window.lazyLoadOptions = { elements_selector: '.lazy' }
  window.app = new Project()
  const cmp  = app.components
  // Do not modify components order
  cmp.navbar           = new Navbar()
  cmp.ajaxTrigger      = new AjaxTrigger('cmp-ajax-trigger')
  cmp.alert            = new Alert('cmp-alert')
  cmp.instance         = new Instance('cmp-instance')
  cmp.clipboard        = new Clipboard('cmp-clipboard')
  cmp.dragAndDrop      = new DragAndDrop('cmp-drag-and-drop')
  cmp.dropzone         = new Dropzone('cmp-dropzone')
  cmp.dropdownTrigger  = new DropdownTrigger('cmp-dropdown-trigger')
  cmp.form             = new Form('cmp-form')
  cmp.input            = new Input('cmp-input')
  cmp.inputCheckbox    = new InputCheckbox('cmp-input-checkbox')
  cmp.inputColorPicker = new InputColorPicker('cmp-colorpicker')
  cmp.inputDatePicker  = new InputDatePicker('cmp-datepicker')
  cmp.inputFile        = new InputFile('cmp-input-file')
  cmp.inputNumber      = new InputNumber('cmp-input-number')
  cmp.inputPassword    = new InputPassword('cmp-input-password')
  cmp.inputRadio       = new InputRadio('cmp-input-radio')
  cmp.inputRange       = new InputRange('cmp-input-range')
  cmp.inputSearch      = new InputSearch('cmp-input-search')
  cmp.inputSelect      = new InputSelect('cmp-input-select')
  cmp.inputTextArea    = new InputTextArea('cmp-textarea')
  cmp.sliderGallery    = new SliderGallery('cmp-slider-gallery')
  cmp.sliderTabs       = new SliderTabs('cmp-slider-tabs')
  cmp.tooltip          = new Tooltip('cmp-tooltip')
  cmp.vimeo            = new Vimeo('cmp-vimeo')
  cmp.modalTrigger     = new ModalTrigger('cmp-modal-trigger')
  cmp.modal            = new Modal('cmp-modal')
  cmp.accordion        = new Accordion('cmp-accordion-trigger')
})
////////////////////// PAGE LOAD ////////////////////////
/////////////////////////////////////////////////////////
window.addEventListener('load', () => {
  pleaseWaitAutoDetection()
  checkUrl()
  checkInnerLinks()
})
