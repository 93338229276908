import BaseComponent from './BaseComponent'

class Modal extends BaseComponent {
  constructor(componentName){
    super(componentName)
    this.actives = app.components.modalTrigger.actives
  }

  init(component) {
    this.addCustomEvents(component)
  }

  addCustomEvents(component) {
    const element  = component.element
    const closeAll = element.getAttribute('data-close-all') == 'true'
    const closeModalButtons = element.getElementsByClassName('close')
    const dialog   = element.getElementsByClassName('modal-dialog')[0]

    // Close Modal when button click
    for (let closeModalButton of closeModalButtons) {
      closeModalButton.addEventListener('click', (e) => {
        // CHECK IF MODAL CONTAINS A FORM
        this.closeOnSubmitInnerForm(closeModalButton)
        this.closeModal(element, closeAll)

        if(closeAll == true) {
          for (let i = 0; i < this.actives.length; i++) {
            this.actives[i].classList.remove('is-active')
          }
          this.actives = []
        }
      })
    }

    // Close modal when dialog or wrapper is clicked
    element.addEventListener('click', (e) => {
      e.stopPropagation()
      if (e.target == element) this.closeModal(element)
    })

    dialog.addEventListener('click', (e) => {
      e.stopPropagation()
			if (e.target == dialog || e.target.dataset.dismiss === 'modal') this.closeModal(element)
    })

    // Close modal when user click ESC key
    window.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) this.closeModal(element)
      return false
    })
  }

  closeModal(modalTarget, closeAll) {
    if(modalTarget) {

      if (!modalTarget.classList.contains('is-active')) return

      modalTarget.classList.add('fading-out')
      setTimeout(() => {
        modalTarget.classList.remove('is-active')
        modalTarget.classList.remove('fading-out')
        modalTarget.classList.remove('is-unfocused')
        this.actives.pop()

        if (this.actives.length > 0) this.actives[this.actives.length - 1].classList.remove('is-unfocused')
        // Unlock main web scroll
        document.body.classList.remove('scroll-locked')

        const dataCleanURL = modalTarget.getAttribute('data-clean-url')
        if(dataCleanURL) app.ajax.rails({url: dataCleanURL, method: 'GET'})

        // Exception for contents
        if(modalTarget.dataset.modal.includes('modal--content--')) {
          const contentsDroppedList = document.getElementsByClassName('list--contents')[0]
          const li = contentsDroppedList.querySelector('li[class*="draggable"]')
          if(li) li.parentElement.removeChild(li)
        }
      }, 200)
    }
  }

  closeAllActiveModals() {
    const activeModals = document.querySelectorAll('li[class*="is-active"]')
    for(const modal of activeModals) {
      modal.classList.remove('is-active')
      modal.classList.remove('is-unfocused')
    }
    this.actives = []

    // Unlock main web scroll
    document.body.classList.remove('scroll-locked')
  }

  closeOnSubmitInnerForm(button) {
    if(button.type === 'submit') app.components.form.submitDataOfForm(button.form)
  }

  create({ id, modal, form, open = false }) {
    const modalsWrapper = document.getElementsByClassName('modals-wrapper')[0]
    const modalParsed   = app.parser.parseToHTML(modal)
    const modalId       = modalParsed.getAttribute('data-modal')
    const modalExist    = document.querySelector(`li[data-modal="${modalId}"]`)

    if(modalExist) {
      // If modal exist, update form
      const modalBody = modalExist.getElementsByClassName('modal__body')[0]
      const modalForm = modalBody.getElementsByClassName('form')[0]
      modalBody.removeChild(modalForm)
      modalBody.appendChild(form)
      // TODO
    } else {
      // If modal doesn't exist, create it and append it
      modalsWrapper.appendChild(modalParsed)
    }

    if(open) {
      const modalCreated = document.querySelector(`li[data-modal="${modalId}"]`)
      app.components.modalTrigger.openModal(modalCreated)
    }
  }
}

export default Modal;
