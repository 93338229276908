import Rails from 'rails-ujs'


class Ajax {
  constructor() {}

  // dataType 'script', response will run success/error method and js.erb file (if exists)
  // dataType 'json',   response will only run success/error method
  rails(options, callback){
    Rails.ajax({
      url         : options.url         || console.error('🛂 An ajax action requires an destination (URL is not being passed)'),
      type        : options.method      || console.error('🛂 An ajax method is required'),
      data        : options.data        || '',
      dataType    : options.dataType    || 'script',
      contentType : options.contentType || false,
      success     : (response, textStatus, errorThrown) => {
        if (callback) callback(response)
      },
      error: function(jqXHR, textStatus){
        console.log('No products found for this params')
      }
    })
  }

  get(url, successCallback) {
    return new Promise((resolve, reject) => {
      const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')

      xhr.open('GET', url)
  		xhr.onreadystatechange = () => {
  			if (xhr.readyState > 3 && xhr.status === 200) {
          if (successCallback) successCallback(app.parser.parseJSON(xhr.response))
  			} else if (xhr.readyState < 3 && xhr.status !== 200) {
          console.error('Get request error: ' + xhr.status)
        }
  		}
  		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
  		xhr.send()

  		return xhr
    })
  }


  post(url, data, contentType = 'application/x-www-form-urlencoded', successCallback) {
    const params = typeof data == 'string' ? data : Object.keys(data).map((k) => {
      return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')
		const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')

		xhr.open('POST', url)
		xhr.onreadystatechange = () => {
			if (xhr.readyState > 3 && xhr.status === 200) {
        console.info('Post success!')
        if (successCallback) successCallback(xhr.responseText)
			} else if (xhr.readyState < 3 && xhr.status !== 200) {
        console.error('Post request error: ' + xhr.status)
      }
		}
    // The header X-Requested-With allows server side frameworks( such Rails,Django etc.) to identify Ajax requests
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
		xhr.setRequestHeader('Content-Type', contentType)
		xhr.send(params)

		return xhr
  }


  getCORS(url, successCallback) {
    let xhr = new XMLHttpRequest()
    if (!('withCredentials' in xhr)) xhr = new XDomainRequest() // fix IE8/9

    xhr.open('GET', url)
    xhr.onload = successCallback
    xhr.send()
    // $INFO: the actual response is found in currentTarget.response or target.responseText.
    // Sample of ta successCallback getting the response
    // getCORS('http://foo.bar/?p1=1&p2=Hello+World', function(request){
    //   var response = request.currentTarget.response || request.target.responseText
    //   console.log(response)
    // })
    return xhr
  }
}


export default Ajax;
