import BaseComponent from './BaseComponent'
import Rails         from 'rails-ujs'

class Form extends BaseComponent {
  constructor(componentName, autoInit){
    super(componentName)
  }

  init(component) {
    this.addCustomEvents(component)
  }

  addCustomEvents(component) {
    if(!component.element.classList.contains('no-watch')) {
      this.watchAllFields(component)
    }

    const inputFiles = component.element.querySelectorAll('input[type="file"]')
    if(inputFiles.length > 0 && browserAllowDragAndDrop()) app.components.inputFile.enableDragAndDrop(component.element)


    const submits = component.element.querySelectorAll('button[type="submit"]')
    for(let submit of submits) {
      if (submit.classList.contains('alternate-submit')) {
        submit.addEventListener('click', (e) => {
          e.preventDefault()

          const alternateSubmit = component.element.querySelector('input[name="alternate_submit"]')
          if(alternateSubmit) alternateSubmit.value = true
          // AJAX POST
          Rails.fire(component.element, 'submit')

          if (alternateSubmit) alternateSubmit.value = false
        })
      }
    }
  }

  submitData (input, justWhenChecked = false, removeRemote = false) {
    const formId = input.dataset.formId
    const form   = document.querySelector(`.form[data-id='${formId}']`)

    if(!justWhenChecked || (input && justWhenChecked && input.checked)) {
      this.submitDataOfForm(form, removeRemote)
    }
  }

  submitDataOfForm (form, removeRemote = false) {
    if(form) {
      if(removeRemote) form.removeAttribute('data-remote')

      const alternateSubmit = form.querySelector('input[name="alternate_submit"]')
      if(alternateSubmit) alternateSubmit.value = true
      // AJAX POST
      Rails.fire(form, 'submit')

      if (alternateSubmit) alternateSubmit.value = false
    }
  }

  watchAllFields(component) {
    // const inputs = document.getElementsByTagName('input')
    component.requiredFields = []
    component.optionalFields = []
    const inputs    = component.element.querySelectorAll('input:not([type=hidden]):not(.hidden)')
    const textareas = component.element.querySelectorAll('textarea:not([type=hidden]):not(.hidden)')
    const selects   = component.element.querySelectorAll('select:not([type=hidden]):not(.hidden)')

    // For webkit browsers
    this.watchAutofilled(inputs)
    this.watchAutofilled(textareas)

    this.storeField(component, inputs, true)
    this.storeField(component, textareas)
    this.storeField(component, selects)
    this.copyField(component)
  }

  watchAutofilled(elements) {
    const autofilled       = (el) => el.classList.add('has-content')
    const notAutofilled    = (el) => el.classList.remove('has-content')
    const onAnimationStart = ({ target, animationName }) => {
      switch (animationName) {
        case 'autofilled':
          return autofilled(target)
        case 'autofilled2':
          return autofilled(target)
        // case 'notAutofilled':
        // console.log('NOT AUTOFILLED')
        //   return notAutofilled(target)
      }
    }

    for(let element of elements) {
      element.addEventListener('animationstart', onAnimationStart, false)
    }
  }

  storeField(component, fields, checkIfPattern = false) {
    for (let field of fields) {

      const required = field.getAttribute('required') == 'true' ? true : false

      if(!!required){
        component.requiredFields.push(field)
      } else {
        component.optionalFields.push(field)
      }

      // Initial Field Check
      this.updateFieldState(component, field, required, checkIfPattern, true)

      field.addEventListener('keyup', () => {
        this.updateFieldState(component, field, required)
        this.updateFormState(component)
      })

      field.addEventListener('blur', () => {
        this.updateFieldState(component, field, required)
        this.updateFormState(component)
      })

      field.addEventListener('focus', () => {
        this.updateFieldState(component, field, required)
        this.updateFormState(component)
      })

      field.addEventListener('change', () => {
        this.updateFieldState(component, field, required)
        this.updateFormState(component)
      })
    }

    // Focus the first required element if there is one
    setTimeout(() => {
      const firstRequiredField = component.requiredFields[0]
      if (!!firstRequiredField) firstRequiredField.focus()
    }, 500)

    // Initial form check
    setTimeout(() => {
      this.updateFormState(component)
    }, 1000)

  }

  updateFieldState(component, field, required = false, checkIfPattern = false, initialCheck = false) {
    if(required && field.value.length > 0) {
      field.dataset.valid = field.checkValidity()

    } else if (required && field.value.length < 1) {
      // TODO MANAGE AUTOCOMPLETE FIELDS ( has value zero by browser bugs)
      field.dataset.valid = initialCheck ? true : false

    } else if (!required && field.value.length < 1) {
      field.dataset.valid = true

    } else if (!required && field.value.length > 0) {
      field.dataset.valid = initialCheck ? true : field.checkValidity()
    }
  }

  checkPattern(field) {
    // checkValidity fails for autocompleted inputs
    // so we check it manually
    const pattern = field.getAttribute('pattern')
    const regexp  = !!pattern ? new RegExp(pattern) : null
    const value   = field.value
    return (!!regexp && !!value) ? regexp.test(value) : false
  }

  updateFormState(component) {
    component.valid
    const requiredFields = this.fieldsAreValid(component.requiredFields)
    const optionalFields = this.fieldsAreValid(component.optionalFields)
    const submitButton   = component.element.querySelector(`button[type='submit']`)

    if (requiredFields && optionalFields) {
      component.element.dataset.valid = true
      component.valid = true
      if(submitButton) {
        submitButton.removeAttribute('data-disabled')
      } else {
        console.warn(`🦑 The form ${component.element.id} doesn't have a submit button`)
      }
    } else {
      component.element.dataset.valid = false
      component.valid = false
      if (submitButton) {
        submitButton.setAttribute('data-disabled', true)
      } else {
        console.warn(`🦑 The form ${component.element.id} doesn't have a submit button`)
      }
    }
  }

  fieldsAreValid(fields) {
    if (!!fields) {
      for (let field of fields) {
        if(field.dataset.valid != 'true') {
          return false
        }
      }
      return true
    }
  }

  copyField(component) {
    const element         = component.element
    const copyInputValue  = element.getElementsByClassName('value--copy')[0]
    const btnConfirmation = element.getElementsByClassName('btn--confirmation')[0]
    const pasteValue      = element.getElementsByClassName('email-field')[0]

    if(!!btnConfirmation) {
      btnConfirmation.addEventListener('click', () => {
        pasteValue.value = copyInputValue.value
      })
    }
  }

  updateForm(id, values, errors) {
    // console.info('Updating form', id)
    const form  = document.querySelector(`form[data-id="${id}"]`)

    if(form) {
      const model = form.dataset.model
      // console.log('VALUES', values)
      // console.log('ERRORS', errors)
      for (const [name, value] of Object.entries(values)) {
        var element = form.querySelector(`[name="${model}[${name}]"]`)
        if(!element) continue
        // // TODO:
        // manage all types: inputs (checkbox, radio) select and textarea
        if(element.type !== 'textarea') {
          element.value = value
          if (element.value.length > 0) element.classList.add('has-content')

          // For textarea with MD(Markdown) functionality
          const inputTextArea = app.components.inputTextArea.getComponentById(element.id)
          if (inputTextArea) inputTextArea.wysiwyg.value(value)
        } else if(element.type !== 'select') {
        // } else if (element.type == 'text' && element.previousElementSibling.classList.contains === 'color-preview-wrapper') {
        //   console.log('COLOR PICKER')
        } else {
          // Is an input
          element.value = value
        }
        // Append error messages, if any.
        const inputErrors = errors[name]
        if(inputErrors) this.generateError(element, inputErrors)
      }
    } else {
      // console.warn(`🦑 The <form data-id="${id}"> doesn't exist, so i can't update it`)
    }
  }

  appendInputs({ id, fieldsets }) {
    const form = document.querySelector(`form[data-id="${id}"]`)
    if (form) {
      const tempFieldsets = form.getElementsByClassName('temp-fieldsets')[0]
      if(tempFieldsets) {
        tempFieldsets.appendChild(app.parser.parseToHTML(fieldsets))
      }
    } else {
      console.warn(`🦑 The <form data-id="${id}"> doesn't exist, so i can't append anything`)
    }
  }

  generateError(element, errors) {
    const pString = `<p class='form-msg alert'>${errors.join('<br>')}</p>`
    const pTag    = app.parser.parseToHTML(pString)

    const inputWrapper  = element.parentElement
    const currentErrors = inputWrapper.getElementsByClassName('alert')

    for(let currentError of currentErrors) {
      inputWrapper.removeChild(currentError)
    }
    // Apend error message
    insertAfter(pTag, element)
  }

  clearForm(id) {
    console.info('Clearing form')
    const form  = document.querySelector(`form[data-id="${id}"]`)
    if(form) {

      // Remove the temp fieldsets (not the wrapper) which are created dinamically if needed
      const tempFieldsets = form.getElementsByClassName('temp-fieldsets')[0]
      if (tempFieldsets) {
        while (tempFieldsets.firstChild) {
          tempFieldsets.removeChild(tempFieldsets.firstChild)
        }
      }

      // Clear values
      const inputs    = form.querySelectorAll('input:not([type=hidden]):not(.hidden)')
      const textareas = form.querySelectorAll('textarea:not([type=hidden]):not(.hidden)')
      const selects   = form.querySelectorAll('select:not([type=hidden]):not(.hidden)')

      for(let input of inputs) {
        input.value = ''

        let colorAsHexadecimal
        if(input.previousElementSibling) colorAsHexadecimal = input.previousElementSibling.classList.contains('color-preview-wrapper')
        if (input.type !== 'color' && !colorAsHexadecimal) {
          input.classList.remove('has-content')
        }
      }

      for(let textarea of textareas) {
        textarea.value = ''
        textarea.classList.remove('has-content')

        // For textarea with MD(Markdown) functionality
        const inputTextArea = app.components.inputTextArea.getComponentById(textarea.id)
        if (inputTextArea) inputTextArea.wysiwyg.value('')
      }

      for(let select of selects) {
        select.value = ''
        select.classList.remove('has-content')
      }

      // REMOVES ERROR MESSAGES
      const errors = form.querySelectorAll('p[class*="form-msg"]')
      for(let error of errors) error.parentNode.removeChild(error)
    } else {
      console.warn(`🦑 The <form data-id="${id}"> doesn't exist, so i can't clear it`)
    }
  }

  repaint({ formId, newForm }) {
    const form = document.querySelector(`form[data-id="${formId}"]`)
    if(form) {
      const parentElement = form.parentElement
      parentElement.removeChild(form)
      parentElement.appendChild(app.parser.parseToHTML(newForm))
    } else {
      console.warn(`🦑 The <form data-id="${formId}"> doesn't exist, so i can't repaint it`)
    }
  }
}

export default Form
